<template>
  <div class="w-full bg-gray-50 pb-4 flex flex-row" style="height: calc(100vh - 217px) !important;">
    <div class="w-1/2 p-2 overflow-hidden">
      <div class="bg-gray-50 rounded border-gray-200 flex flex-wrap overflow-y-scroll">
        <div v-for="pic in pictureTypes" v-bind:key="pic.id" class="pl-2 pt-2 w-1/3" @dragstart="dragStart(pic, $event)" @dragover.prevent @drop="dragFinish(pic.item, $event)">
          <upload-picture-zone  v-on:progress="(p) => setProgress(pic.item, p)" v-on:files="u => uploadFiles(pic.item, u)" background="bg-white" :class="['relative h-26 bg-white rounded border-2 cursor-pointer', pic.mandatory && notPresent(pic.item) ? 'border-blue-500' : 'border-white']">
            <div v-if="progress!=null&&source==pic.item" style="background-color: #222; animation-name: upload-color; animation-duration: 0.8s; animation-iteration-count: infinite;" class="w-full h-full flex flex-row justify-center items-center text-blue-500">Uploading</div>
            <div v-else class="w-full h-full flex flex-row justify-center items-center">
              <img :src="getImage(pic.item)" class="w-full h-24">
            </div>
            <div v-if="present(pic.item)" class="absolute bottom-0 w-full text-center bg-gray-800 text-white text-sm opacity-25">{{pic.title}}</div>
          </upload-picture-zone>
        </div>
        <div v-for="pic in getOtherPictures()" v-bind:key="pic.id" class="pl-2 pt-2 w-1/3" @dragstart="dragStart(pic, $event)" @dragover.prevent @drop="dragFinish(pic.item, $event)">
          <upload-picture-zone  v-on:progress="(p) => setProgress(pic.item, p)" v-on:files="u => uploadFiles(pic.item, u)" background="bg-white" :class="['relative h-26 bg-white rounded border-2 cursor-pointer', pic.mandatory && notPresent(pic.item) ? 'border-blue-500' : 'border-white']">
            <div v-if="progress!=null&&source==pic.item" style="background-color: #222; animation-name: upload-color; animation-duration: 0.8s; animation-iteration-count: infinite;" class="w-full h-full flex flex-row justify-center items-center text-blue-500">Uploading</div>
            <div v-else class="w-full h-full flex flex-row justify-center items-center">
              <img :src="getImage(pic.item)" class="w-full h-24">
            </div>
            <div v-if="present(pic.item)" class="absolute bottom-0 w-full text-center bg-gray-800 text-white text-sm opacity-25">{{ pic.item.toLowerCase().startsWith('other') ? 'Other' : pic.title }}</div>
          </upload-picture-zone>
        </div>
        <div class="pl-2 pt-2 w-1/3"  @dragover.prevent @drop="dragFinish('other', $event)">
          <upload-picture-zone v-on:progress="p => setProgress('small', p)" v-on:files="u => uploadFiles('small', u)" background="bg-blue-50" class="w-full h-26 bg-blue-50 rounded border-2 border-blue-50 flex justify-center items-center text-sm text-blue-400 hover:bg-blue-100 hover:text-blue-500 cursor-pointer">
            <div v-if="progress!=null&&source=='small'" style="background-color: #222; animation-name: upload-color; animation-duration: 0.8s; animation-iteration-count: infinite;" class="w-full h-full flex flex-row justify-center items-center">Drop here {{ nil(progress) }}</div>
            <div v-else class="w-full h-full flex flex-row justify-center items-center">Drop here</div>
          </upload-picture-zone>
        </div>
      </div>
    </div>
    <div class="w-1/2 p-2 overflow-hidden">
      <div class="rounded border-2 border-gray-200 flex flex-wrap pr-2 pb-2 bg-white h-full overflow-y-scroll">
        <div class="pl-2 pt-2 w-full"  @dragover.prevent @drop="dragFinish('delete', $event)">
          <div class="w-full h-10 bg-red-50 rounded border-2 border-red-50 flex justify-center items-center text-sm text-red-400 hover:bg-red-100 hover:text-red-500 cursor-pointer">
            <div class="w-full h-full flex flex-row justify-center items-center">Drop here to delete</div>
          </div>
        </div>
        <div class="pl-2 pt-2 w-full" @dragover.prevent @drop="dragFinish('big', $event)">
          <upload-picture-zone v-on:progress="(p) => setProgress('big', p)" v-on:files="u => uploadFiles('big', u)" background="bg-blue-50" class="sticky top-0 h-16 bg-blue-50 rounded text-blue-500 flex flex-col items-center justify-center cursor-pointer">
            <div v-if="progress!=null&&source=='big'" style="background-color: #222; animation-name: upload-color; animation-duration: 0.8s; animation-iteration-count: infinite;" class="w-full h-full flex flex-row justify-center items-center">Drop files here or on targets</div>
            <div v-else class="w-full h-full flex flex-row justify-center items-center">Drop files here or on targets</div>
          </upload-picture-zone>
        </div>
        <div class="h-full w-full flex flex-wrap overflow-y-scroll">
          <div v-for="pic in pictures()" v-bind:key="pic.id" class="pl-2 pt-2 w-1/3" draggable="true" @dragstart="dragStart(pic, $event)" @dragover.prevent @dragend="dragEnd" @drop="dragFinish(pic.item, $event)">
            <div background="bg-white" class="relative h-26 bg-white rounded border-2 cursor-pointer border-white">
              <div class="w-full h-full flex flex-row justify-center items-center">
                <img :src="pic.thumbnailUrl" class="w-full h-24">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {coalesce} from "@/common";
import {nil} from "@/common";
import UploadZone from '@/components/upload-picture-zone';

export default {
  name: "Pictures",
  components: {
    'upload-picture-zone': UploadZone
  },
  data() {
    return {
      pictureTypes: [
        {title: "3/4 front left", item:'front-left', mandatory: true},
        {title: "3/4 rear right", item:'rear-right', mandatory: true},
        {title: "Inside door right", item:'front-door-right-i', mandatory: true},
        {title: "Front",  item:'front', mandatory: false},
        {title: "Rear",  item:'rear', mandatory: false},
        {title: "Inside front",  item:'inside-front', mandatory: true},
        {title: "Dashboard",  item:'dashboard', mandatory: true},
        {title: "Engine",  item:'engine', mandatory: false},
        {title: "Trunk", item:'trunk', mandatory: false},
        {title: "Left", item:'left', mandatory: false},
        {title: "Right",  item:'right', mandatory: false},
        {title: "Books + keys", item:'maintenance-key', mandatory: true}
      ],
      loading: true,
      source: null,
      progress: null,
      dragging: null
    }
  },
  methods: {
    nil: nil,
    lead() {
      return this.$store.getters['seller/lead'];
    },
    pictures() {
      if(this.$store.getters['seller/lead'].pictures === undefined || this.$store.getters['seller/lead'].pictures === null)
        this.$store.getters['seller/lead'].pictures = [];
      return this.$store.getters['seller/lead'].pictures;
    },
    selectMenu(menu) {
      this.$emit('select-detail-menu', menu);
    },
    setProgress(source, value) {
      this.source = source
      this.progress = value
    },
    uploadFiles(source, files) {
      let added = false;
      _.each(files, file => {
        if('photoId' in file)
          _.remove(this.lead().condition.pictures, p => p.photoId == file.photoId);
          _.remove(this.pictures(), p => p.photoId == file.photoId);
        const current = _.filter(this.lead().condition.pictures, p => p.item === source);
        //Is there already a picture with this item in the list
        if (current.length>0) {
          this.pictures().push(_.cloneDeep(current[0]));
          _.remove(this.lead().condition.pictures, p => p.item === source);
          added = true;
          this.lead().condition.pictures.push({
            item: source,
            photoId: 'image_id' in file ? file.image_id : file.photoId,
            photoGUID: file.photoGUID,
            thumbnailUrl: 'thumbnail_url' in file ? file.thumbnail_url : file.thumbnailUrl
          });
        }
        if (!added && source!=='big') {
          let mv = coalesce(_.max(_.map(_.filter(this.lead().condition.pictures, p => p.item.toLowerCase().startsWith('other')), p => parseInt(p.item.split('-')[1]))), 0) + 1;
          this.lead().condition.pictures.push({
            item: source==='small' ? `other-${mv}` : source,
            photoId: 'image_id' in file ? file.image_id : file.photoId,
            photoGUID: file.photoGUID,
            thumbnailUrl: 'thumbnail_url' in file ? file.thumbnail_url : file.thumbnailUrl
          });
        } else if(!added) {
          this.pictures().push({
            item: 'big',
            photoId: 'image_id' in file ? file.image_id : file.photoId,
            photoGUID: file.photoGUID,
            thumbnailUrl: 'thumbnail_url' in file ? file.thumbnail_url : file.thumbnailUrl
          });
        }
      });
    },
    getImage(source) {
      const items = _.filter(this.lead().condition.pictures, p => p.item === source);
      if (items.length>0) {
        return items[0].thumbnailUrl;
      } else {
        return `${require(`@/assets/img/picturetypes/${source}.svg`)}`;
      }
    },
    notPresent(source) {
      const items = _.filter(this.lead().condition.pictures, p => p.item === source);
      return items.length===0;
    },
    present(source) {
      const items = _.filter(this.lead().condition.pictures, p => p.item === source);
      return items.length!==0;
    },
    getOtherPictures() {
      let idx = 0;
      return _.orderBy(_.filter(this.lead().condition.pictures, p => p.item.toLowerCase().startsWith('other')) , p => {
        if(p.item==='other') p.item='other-' + idx;
        idx++;
        return parseInt(p.item.split('-')[1])
      });
    },
    dragStart(which) {
      console.log(which);
      this.dragging = which;
    },
    dragEnd() {
      this.dragging = null;
    },
    dragFinish(to) {
      if(this.dragging==null) return;
      if(!("photoId" in this.dragging)) {
        const items = _.filter(this.lead().condition.pictures, p => p.item === this.dragging.item);
        if (items.length == 0)  return;
        this.dragging = items[0];
      }
      if(to=='delete') {
        _.remove(this.lead().condition.pictures, p => p.photoId == this.dragging.photoId);
        _.remove(this.pictures(), p => p.photoId == this.dragging.photoId);
        this.dragging = null;
        return;
      }
      this.uploadFiles(to, [_.cloneDeep(this.dragging),]);
      this.dragging = null;
    },
  },
  watch: {
  },
  created() {
    this.loading = false;
  },
}
</script>

<style scoped>

</style>